<template>
  <div class="appointmentList-view">
    <p>商户信息</p>

    <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
      <template v-slot:table>
        <el-table-column align="center" type="index" width="50" label="序号"/>
        <el-table-column prop="businessCode" align="center" label="申请单编号" />
        <el-table-column prop="merchantName" align="center" label="商户名称" >
          <template slot-scope="scope">
            {{scope.row.merchantName || '未填写'}}
          </template>
        </el-table-column>
        <el-table-column prop="subMchid" align="center" label="商户号">
          <template slot-scope="scope">
            {{scope.row.subMchid || '未生成'}}
          </template>
        </el-table-column>
        <el-table-column prop="subMchid" align="center" label="状态">
          <template slot-scope="scope">
            {{scope.row.applymentState | applymentStateFormat}}
          </template>
        </el-table-column>
        <el-table-column prop="nickeName" align="center" label="创建员工" />
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="
                toApplyInfo(scope.row);"  v-if="scope.row.applymentState == 'APPLYMENT_STATE_EDITTING'||!scope.row.applymentState || scope.row.applymentState == 'APPLYMENT_STATE_REJECTED'">
              继续填写
            </el-button>
            <el-button type="text" @click="
                seeDetail(scope.row);">
              查看
            </el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  queryCommissionRecordDetail
} from "@/api/financing";
import { getDataTimeSec } from "@/utils";
import {getWxMerchantInfo} from "../../api/wxMerchant";
export default {
  name: "Merchant",
  components: {
    commonTable,
  },
  data() {
    return {
      value: "",
      tableData: [],
      tableDataList:[],
      optionList:'', //全部分类
      loading:'',
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      detallId:'' , //详情id
    };
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  filters:{
    applymentStateFormat(status){
      if (!status){
        return '待提交'
      }else if (status == 'APPLYMENT_STATE_EDITTING'){
        return  '编辑中'
      }else if (status == 'APPLYMENT_STATE_AUDITING'){
        return  '审核中'
      }else if (status == 'APPLYMENT_STATE_REJECTED'){
        return  '已驳回'
      }else if (status == 'APPLYMENT_STATE_TO_BE_CONFIRMED'){
        return  '待账户验证'
      }else if (status == 'APPLYMENT_STATE_TO_BE_SIGNED'){
        return  '待签约'
      }else if (status == 'APPLYMENT_STATE_SIGNING'){
        return  '开通权限中'
      }else if (status == 'APPLYMENT_STATE_FINISHED'){
        return  '已完成'
      }
    }
  },
  created(){
    this.getTableData();
  },
  mounted(){

  },
  methods:{
    seeDetail(row){
      this.$router.push({path:'/merchantApplyInfo',query:{
          merchantId:row.id,
          isSee:true
      }})
    },
    toApplyInfo(row){
      this.$router.push({path:'/merchantApplyInfo',query:{ merchantId:row.id,
          isSee:false}})
    },
    //请求表格数据
    getTableData() {
      this.loading = true;
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      getWxMerchantInfo(data)
      .then((res) => {
        this.loading = false;
        this.tableData = res.data.list || [];
        this.total = res.data.total;
        console.log(this.tableData)
      })
      .catch((err) => {
        this.loading = false;
        this.tableData = [];
      });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.getTableData();
    },
  }
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>
